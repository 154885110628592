<template>
    <div class="course-presentation" v-if="testi">
        <top-decorations></top-decorations>
        <div class="container">
            <div class="small-intro">
                  <div class="intro-section container">
                    <div class="col-lg-6">
                    <h1 class="light">Presentation</h1>
                    <h3 class="light">
                        The MHPC is structured in <span class="green">three parts</span>, as outlined here below:
                    </h3>
                    </div>
                </div>
            </div>
            <div class="row course-list">
                <div class="col-lg-4 col-md-12 mb-4 mt-sm-3 ">
                    <div class="bullet-list-deco green-deco">
                        <h6>course part 1</h6>
                        <p>{{ testi.corso_pt1.corsi_pt1_durata }}</p>
                    </div>
                    <h5 class="green mt-lg-3 mt-3" >{{ testi.corso_pt1.corsi_pt1_titolo }}</h5>
                    <div class="mt-lg-4">
                        <div v-html="testi.corso_pt1.corsi_pt1_summary"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 mb-4 mt-sm-3 ">
                    <div class="bullet-list-deco green-deco">
                        <h6>course part 2</h6>
                        <p>{{ testi.corso_pt2.corsi_pt2_durata }}</p>
                    </div>
                    <h5 class="green mt-lg-3 mt-3" >{{ testi.corso_pt2.corsi_pt2_titolo }}</h5>
                    <div class="mt-lg-4">
                        <div v-html="testi.corso_pt2.corsi_pt2_summary"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 mb-4 mt-sm-3  ">
                    <div class="bullet-list-deco green-deco">
                        <h6>course part 3</h6>
                        <p>{{ testi.corso_pt3.corsi_pt3_durata }}</p>
                    </div>
                    <h5 class="green mt-lg-3 mt-3" >Practical HPC Project</h5>
                    <div class="mt-lg-4">
                        <div v-html="testi.corso_pt3.corsi_pt3_summary"></div>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="two-column-layout section section-1" style="backgroundColor:white">
            <div class="container">
                <div class="row mt-5">
                    <div class="col-lg-6 order-lg-1 order-md-2 text">
                        <h6>
                            ABOUT US
                        </h6>
                        <h3 class="light">
                            {{testi.about.corsi_about_titolo}}
                        </h3>
                        <div v-html="testi.about.corsi_about_testo"></div>
                    </div>
                    <div class="col-lg-6 order-lg-2 order-md-1 image d-none d-lg-block">
                    </div>
                </div>
                <div class="row mt-5 d-none">
                    <div class="col-lg-6 order-lg-2 order-md-1 text">
                       <div v-html="testi.about.corsi_about_testo_2"></div>
                        <a class="read-more" href="">read more</a>
                    </div>
                    <div class="col-lg-6 order-lg-1 order-md-2 image">
                    </div>
                </div>
                <div class="row part-one mt-5">
                <div class="col-lg-6 order-lg-2 order-md-1 image">
                </div>
                    <div class="col-lg-6 order-lg-2 order-md-1 text">
                        <h6>
                            Part one
                        </h6>
                        <h3 class="light text-uppercase">
                            {{ testi.corso_pt1.corsi_pt1_titolo }}
                        </h3>
                        <div v-html="testi.corso_pt1.corsi_pt1_testo"></div>  
                        <router-link class="cta-big-link" to="/modules-0">
                            <button class="cta-big light-bg icon-lecturer m-md-3">
                                <label class="top">modules</label>
                                <label class="bottom">September - February</label>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="two-column-layout section section-2" style="backgroundColor:#FAFAFA">
            <div class="container">
                <div class="row mt-5">
                    <div class="col-lg-6 order-lg-1 order-md-2 text">
                        <h6>
                            Part TWO
                        </h6>
                        <h3 class="light text-uppercase">
                            {{ testi.corso_pt2.corsi_pt2_titolo }}
                        </h3>
                        <div v-html="testi.corso_pt2.corsi_pt2_testo"></div>  
                        <router-link class="cta-big-link" to="/modules-0">
                            <button class="cta-big light-bg icon-lecturer m-md-3">
                                <label class="top">modules</label>
                                <label class="bottom">March - June</label>
                            </button>
                        </router-link>
                    </div>
                    <div class="col-lg-6 order-lg-2 order-md-1 image">
                    </div>
                </div>
            </div>
        </div>
        <div class="two-column-layout section section-3" style="backgroundColor:#293446">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-6 order-lg-1 order-md-2 text">
                        <h6>
                            Part Three
                        </h6>
                        <h3 class="light text-uppercase">
                             {{ testi.corso_pt3.corsi_pt3_titolo }}
                        </h3>
                        <div v-html="testi.corso_pt3.corsi_pt3_testo"></div>  
                         <router-link class="cta-big-link" to="/how-apply">
                            <button class="cta-big dark-bg icon-book m-md-3">
                            <label class="top">HPC FOR STUDENTS</label>
                            <label class="bottom" style="color:#fff" >How to Apply</label>
                        </button>
                        </router-link>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="loading" v-else></div>
</template>

<script>
import TopDecorations from "@/components/TopDecorations.vue";

export default {
    name: "course-presentation",
    components: {
        TopDecorations
    },
    data:()=>{
        return {
            testi: '',
        }
    },
    created() {
    fetch(`${process.env.VUE_APP_ENDPOINT}/custom-texts/presentation`)
      .then((response) => response.json())
      .then((json) => {
        this.testi = json.data
      })
    }
};
</script>

<style lang="scss" scoped>
    .course-presentation {
        .two-column-layout {
            .row:nth-child(odd) .image {
                background-image: url("../assets/presentation-1.png");
            }
            .row:nth-child(even) .image {
                background-image: url("../assets/presentation-2.png");
            }
        }
        .two-column-layout.section-2 {
            .row .image {
                background-image: url("../assets/presentation-3.png");
                max-height: 540px;
            }
        }
    }
    .course-presentation .two-column-layout .part-one.row .image{
        background-image: url("../assets/presentation-2.png");
    }
    .course-presentation .part-one.row .text{
    padding: 1rem 0rem 0rem 3rem;
    }
        
    .course-list {
        &>div {
            position: relative;
            padding-right: 3.125rem;
            &> div {
                //padding-left: 40px;
                h6,p{
                    margin:0;
                    line-height: 22px;
                }
            }
        }
    }


@media (max-width:992px){
    .course-presentation .part-one.row .text {
        padding: 1rem 0rem 0rem 0rem;
    }
}
@media (max-width:576px){
    .course-list > div {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>